.credit-container {
  margin: 0rem 1rem 0rem;
  display: flex;
}

@media (max-width: 1380px) {
}

@media (max-width: 768px) {
  .credit-container {
    justify-content: flex-start;
  }
  .credit-text {
    font-size: 1rem;
  }
  .credit-text-link {
    font-size: 1rem;
  }
}
