.bg-image {
  display: flex;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)),
    url(../../assets/images/home/pawel-czerwinski-yn97LNy0bao-unsplash-_1_.webp);
  background-size: cover;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.bg-image-text-container {
  color: white;
  padding: 10rem;
  line-height: 1;
  height: 100vh;
}

.bg-image-text {
  font-size: 12rem;
}

.bg-image-btn-container {
  display: flex;
  justify-content: center;
  margin: 4rem 2rem 0rem;
  padding: 0rem 2rem 0rem;
}

.bg-image-btn {
  padding: 1rem 6rem 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 0.2rem;
  background-color: black;
  color: white;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
}

.bg-image-text-subtitle {
  font-size: 5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.credit-container {
  display: flex;
  justify-content: flex-end;
}

.credit-text {
  color: white;
  font-size: 1.5rem;
}

.credit-text-link {
  color: white;
}

@media (max-height: 1380px) {
  .bg-image-text {
    font-size: 8rem;
  }
  .bg-image-text-subtitle {
    font-size: 4rem;
  }
}

@media (max-width: 1380px) {
  .bg-image-btn {
    padding: 1rem 2rem 1rem;
    font-size: 1.4rem;
  }

  .bg-image-text-container {
    display: flex;
    padding: 6rem;
  }
  .bg-image-text-subtitle {
    justify-content: center;
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .bg-image-btn {
    padding: 1rem 2rem 1rem;
    font-size: 1.4rem;
  }

  .bg-image-text-container {
    display: flex;
    padding: 3rem;
  }
  .bg-image-text {
    font-size: 6rem;
  }
  .bg-image-text-subtitle {
    font-size: 3rem;
  }
}

@media (max-width: 600px) {
  .bg-image-btn {
    padding: 1rem 2rem 1rem;
    font-size: 1.5rem;
  }

  .bg-image-text-container {
    display: flex;
    padding: 1rem;
  }

  .bg-image-text {
    font-size: 4rem;
  }
  .bg-image-text-subtitle {
    font-size: 2.5rem;
  }
}
